<template>
  <b-sidebar
    id="add-new-support-cases-sidebar"
    :visible="isAddNewSupportCasesSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-support-cases-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Support Case</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Summary -->
          <validation-provider
            #default="validationContext"
            name="summary"
            rules="required"
          >
            <b-form-group label-for="summary">
              <template v-slot:label>
                Summary <span class="required">*</span>
              </template>
              <b-form-input
                id="summary"
                v-model="supportCaseData.summary"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Request Type -->
          <validation-provider
            #default="validationContext"
            name="request type"
            rules="required"
          >
            <b-form-group label-for="requestType">
              <template v-slot:label>
                Request Type <span class="required">*</span>
              </template>
              <b-form-select
                id="requestType"
                v-model="supportCaseData.requestTypeId"
                :options="requestTypes"
                :state="getValidationState(validationContext)"
                value-field="id"
                text-field="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Priority -->
          <validation-provider
            #default="validationContext"
            name="priority"
            rules="required"
          >
            <b-form-group label-for="priority">
              <template v-slot:label>
                Priority <span class="required">*</span>
              </template>
              <b-form-select
                id="priority"
                v-model="supportCaseData.priorityId"
                :options="priority"
                :state="getValidationState(validationContext)"
                value-field="id"
                text-field="name"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <vue-editor
            id="add-description"
            useCustomImageHandler
            @image-added="handleImageAdded"
            v-model="supportCaseData.description"
            :editor-toolbar="customToolbar"
            placeholder="Description is required"
          />
          <input
            type="file"
            @change="addAttachments"
            ref="file"
            style="display: none"
          />

          <div id="file-upload" v-cloak @click="$refs.file.click()">
            <div
              @drop.prevent="dragAttachments"
              @dragover.prevent
              id="file-drag-and-drop"
            >
              <h5>+ Add Attachments</h5>
            </div>
          </div>
          <div id="uploaded-files" v-for="(file, id) in attachments" :key="id">
            <b-row class="d-flex justify-conetnt-between">
              <b-col>
                <feather-icon icon="FileIcon" class="mr-75" />
                {{
                  file.name.length > 30
                    ? file.name.substring(0, 20) + "..."
                    : file.name
                }}
                ({{ file.size }})
              </b-col>
              <b-col class="col-auto icon">
                <feather-icon icon="XIcon" @click="removeAttachmet(file)" />
              </b-col>
            </b-row>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="
                supportCaseData.summary == '' ||
                supportCaseData.requestTypeId == '' ||
                supportCaseData.priorityId == '' ||
                supportCaseData.description == '' ||
                isLoading
              "
              ><div class="d-flex align-items-center">
                <b-spinner
                  small
                  label="Text Centered"
                  v-if="isLoading"
                ></b-spinner>
                <span class="button-title">Add</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BLink,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, confirmed, min } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
// import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { VueEditor } from "vue2-editor";
// import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    // editor: Editor,
    BSidebar,
    VueEditor,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BLink,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSupportCasesSidebarActive",
    event: "update:is-add-new-support-cases-sidebar-active",
  },
  props: {
    isAddNewSupportCasesSidebarActive: {
      type: Boolean,
      required: true,
    },
    requestTypes: {
      type: Array,
      required: true,
    },
    priority: {
      type: Array,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      content: "Some initial content",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      required,
      alphaNum,
      email,
      // countries,
      togglePassword: false,
      confirmed,
      min,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmpasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    organizationNames() {
      return store.getters["app-organization/organizationsList"];
    },
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let reader = new FileReader();
      reader.onloadend = function () {
        Editor.insertEmbed(cursorLocation, "image", reader.result);
        Editor.formatText(cursorLocation, cursorLocation + 1, "alt", file.name);
        resetUploader();
      };
      reader.readAsDataURL(file);
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    let isLoading = ref(false);
    let attachments = ref([]);
    let userData = JSON.parse(localStorage.getItem("userData"));

    const blankSupportCaseData = {
      requestTypeId: "",
      priorityId: "",
      summary: "",
      description: "",
      attachments: [],
      accountId: "",
      OrganizationId: "",
      organizationName: "",
    };

    const supportCaseData = ref(
      JSON.parse(JSON.stringify(blankSupportCaseData))
    );
    const resetsupportCaseData = () => {
      supportCaseData.value = JSON.parse(JSON.stringify(blankSupportCaseData));
      attachments.value = [];
    };
    const dragAttachments = (e) => {
      let files = e.dataTransfer.files;
      [...files].forEach((file) => {
        attachments.value.push(file);
      });
    };

    const addAttachments = (e) => {
      const files = e.target.files;
      [...files].forEach((file) => {
        attachments.value.push(file);
      });
    };

    const removeAttachmet = (e) => {
      for (const [key, value] of Object.entries(attachments.value)) {
        if (e.name == value.name) {
          attachments.value.splice(key, 1);
        }
      }
    };
    const onSubmit = () => {
      let formData = new FormData();
      for (let i = 0; i < attachments.value.length; i++) {
        let file = attachments.value[i];
        formData.append("file" + i + "", file);
      }
      formData.append("accountId", userData.accountId);
      formData.append("OrganizationId", userData.company_id);
      formData.append("OrganizationName", userData.company_name);
      formData.append("requestTypeId", supportCaseData.value.requestTypeId);
      formData.append("priority_id", supportCaseData.value.priorityId);
      formData.append("summary", supportCaseData.value.summary);
      formData.append("description", supportCaseData.value.description);
      isLoading.value = true;
      store
        .dispatch("app-support-cases/addSupportCases", formData)
        .then(() => {
          isLoading.value = false;
          emit("refetch-data");
          emit("update:is-add-new-support-cases-sidebar-active", false);
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Support cases added successfully`,
            },
          });
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Support case could not added`,
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsupportCaseData);

    return {
      isLoading,
      attachments,
      addAttachments,
      removeAttachmet,
      dragAttachments,
      supportCaseData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.b-form-select {
  margin: 0px !important;
  width: 100%;
}

#file-upload {
  margin-top: 1rem;
  cursor: pointer;
  height: 5rem;
  border: 2px dotted $custom-control-border-color;
  border-radius: 0.357rem;
  #file-drag-and-drop {
    h5 {
      text-align: center;
      margin-top: 1.5rem;
    }
  }
}
#uploaded-files {
  margin-top: 5px;
  border-radius: 0.357rem;
  padding-left: 5px;
  border: 1px solid $custom-control-border-color;
}
.icon {
  cursor: pointer;
}
.required {
  color: $required;
  font-size: 1rem;
}
.btn-secondary {
  background-color: $white !important;
  color: $black !important;
  border-color: $black !important;
}
#add-description > div.ql-editor > p > img {
  max-width: 330px;
}
</style>
