<template>
  <div>
    <support-cases-list-add-new
      :is-add-new-support-cases-sidebar-active.sync="
        isAddNewSupportCasesSidebarActive
      "
      :request-types="requestTypes"
      :priority="priority"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="supportFilter">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" lg="5" md="12" sm="12" xs="12">
            <b-row>
              <b-col
                class="col-auto d-flex align-items-center justify-content-start mb-1 mb-md-0 p-1 px-md-1 p-md-0"
              >
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col cols="12" lg="7" md="12" sm="12" xs="12">
            <b-row class="d-flex justify-content-end">
              <b-col lg="4" md="4" sm="4" xs="12" class="p-1 px-md-1 p-md-0">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </b-col>
              <b-col class="p-1 px-md-1 p-md-0 pl-md-0 col-auto">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="isSearch = true"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
                <b-button
                  v-if="userData.accountId"
                  variant="primary"
                  @click="isAddNewSupportCasesSidebarActive = true"
                >
                  <span class="text-nowrap">Add Support Case</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col lg="3" md="3" sm="3" xs="12" class="p-1 px-md-1 p-md-0">
            <v-select
              v-model="statusSelect"
              :options="status"
              placeholder="Select Status"
              label="name"
            />
          </b-col>

          <b-col lg="3" md="3" sm="3" xs="12" class="p-1 px-md-1 p-md-0">
            <v-select
              v-model="prioritySelect"
              :options="priority"
              placeholder="Select Priority"
              label="name"
            />
          </b-col>
          <b-col
            lg="3"
            md="3"
            sm="3"
            xs="12"
            v-if="
              userData.role_name == 'GLOBAL_ADMIN' ||
              userData.role_name == 'ACCOUNT_MANAGER'
            "
            class="p-1 px-md-1 p-md-0"
          >
            <v-select
              v-model="organziationSelect"
              :options="organization"
              placeholder="Select Organziation"
              label="name"
              @input="getUser"
            >
              <span slot="no-options"> {{ noOptionMessage }} </span>
            </v-select>
          </b-col>
          <b-col
            lg="3"
            md="3"
            sm="3"
            xs="12"
            v-if="
              userData.role_name == 'GLOBAL_ADMIN' ||
              userData.role_name == 'ACCOUNT_MANAGER'
            "
            class="p-1 px-md-1 p-md-0"
          >
            <v-select
              v-model="userSelect"
              :options="user"
              placeholder="Select User"
              label="first_name"
              :disabled="organziationSelect == null"
            />
          </b-col>
          <b-col
            lg="3"
            md="3"
            sm="3"
            xs="12"
            v-if="userData.role_name == 'ORG_ADMIN'"
            class="p-1 px-md-1 p-md-0"
          >
            <v-select
              v-model="userSelect"
              :options="user"
              placeholder="Select User"
              label="first_name"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <div class="support-cases">
        <b-table
          ref="refSupportCaseListTable"
          class="position-relative"
          :items="fetchSupportCases"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="viewSupportCase"
        >
          <!-- Column: SupportCases -->
          <template #cell(supportcases)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.first_name + data.item.last_name)"
                  :variant="`light-${resolveSupportCasesRoleVariant(
                    data.item.role
                  )}`"
                  :to="{
                    name: 'apps-support-cases-view',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                :to="{
                  name: 'apps-support-cases-view',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                <span v-if="data.item.first_name">
                  {{ data.item.first_name + " " + data.item.last_name }}
                </span>
              </b-link>
              <small class="text-muted"> @{{ data.item.id }}</small>
            </b-media>
          </template>

          <template #cell(id)="data">
            {{ data.item.key }}
          </template>

          <template #cell(reporterEmail)="data">
            <div class="text-nowrap custom-email">
              <span class="align-text-top text-capitalize">{{
                data.item.fields.reporter.emailAddress
              }}</span>
            </div>
          </template>
          <template #cell(summary)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.fields.summary
              }}</span>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.fields.status.name
              }}</span>
            </div>
          </template>
          <template #cell(priority)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.fields.priority.name
              }}</span>
            </div>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSupportCases"
              :per-page="perPage"
              first-number
              limit="3"
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watchEffect } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useSupportCasesList from "./useSupportCasesList";
import supportCasesStoreModule from "../supportCasesStoreModule";
import SupportCasesListAddNew from "./SupportCasesListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@axios";

export default {
  components: {
    SupportCasesListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BProgress,
    BProgressBar,
    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      user: [],
      extraUtilizedHoursText: "",
    };
  },
  beforeMount() {
    this.getOrganizationsForRole();
    this.getUser();
  },
  methods: {
    checkUtilizedHours(utilize, total) {
      this.extraUtilizedHoursText = `${(
        parseFloat(utilize) - parseFloat(total)
      ).toFixed(1)}h exceed`;
      return (parseFloat(utilize) - parseFloat(total)).toFixed(1);
    },
    activate(id) {
      this.perPage = id;
    },
    viewSupportCase(item) {
      this.$router.push("/apps/support-cases/view/" + item.key);
    },
    getUser() {
      this.userSelect = "";
      if (
        this.organziationSelect &&
        (this.userData.role_name == "GLOBAL_ADMIN" ||
          this.userData.role_name == "ACCOUNT_MANAGER")
      ) {
        const userName = [];
        axios
          .get(`/user/organization/${this.organziationSelect.id}/all`)
          .then((response) => {
            for (const [key, value] of Object.entries(response.data.items)) {
              // userName.push(value.first_name + " " + value.last_name);
              userName.push(value);
            }
            this.user = userName;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (this.userData.role_name == "ORG_ADMIN") {
          const userName = [];
          axios
            .get(`/user/organization/${this.userData.company_id}/all`)
            .then((response) => {
              for (const [key, value] of Object.entries(response.data.items)) {
                // userName.push(value.first_name + " " + value.last_name);
                userName.push(value);
              }
              this.user = userName;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
  watch: {
    activeAddSupportCase() {
      this.isAddNewSupportCasesSidebarActive = true;
    },
    searchQuery(newValue) {
      if (newValue == "") {
        this.refetchData();
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.searchQuery = "";
        this.statusSelect = null;
        this.prioritySelect = null;
        this.organziationSelect = null;
        this.userSelect = null;
        this.currentPage = 1;
        this.getOrganizationsForRole();
        this.refetchData();
      },
      immediate: false,
    },
  },
  setup(props, { emit }) {
    const SUPPORT_CASES_APP_STORE_MODULE_NAME = "app-support-cases";
    var userData = JSON.parse(localStorage.getItem("userData"));

    const toast = useToast();
    let requestTypes = ref([]);
    let priority = ref([]);
    let status = ref([]);
    let organization = ref([]);
    let user = ref([]);
    let noOptionMessage = ref("Sorry, data is loading");
    const {
      fetchSupportCases,
      tableColumns,
      perPage,
      currentPage,
      totalSupportCases,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupportCaseListTable,
      refetchData,
      isSearch,
      statusSelect,
      prioritySelect,
      organziationSelect,
      userSelect,
      activeAddSupportCase,

      // UI
      resolveSupportCasesRoleVariant,
      resolveSupportCasesRoleIcon,
      resolveSupportCasesStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useSupportCasesList();

    // Register module
    if (!store.hasModule(SUPPORT_CASES_APP_STORE_MODULE_NAME))
      store.registerModule(
        SUPPORT_CASES_APP_STORE_MODULE_NAME,
        supportCasesStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPORT_CASES_APP_STORE_MODULE_NAME))
        store.unregisterModule(SUPPORT_CASES_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-support-cases/fetchStatus")
      .then((response) => {
        status.value = response.data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching status",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    store
      .dispatch("app-support-cases/fetchPriority")
      .then((response) => {
        priority.value = response.data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching priority",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    store
      .dispatch("app-support-cases/fetchRequestTypes")
      .then((response) => {
        const { values, total } = response.data;
        requestTypes.value = values;
        totalSupportCases.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching request types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const getOrganizationsForRole = () => {
      noOptionMessage.value = "Sorry, data is loading";
      organization.value = [];
      userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.role_name == "GLOBAL_ADMIN") {
        const data = {
          region_id: userData.default_region_id,
        };
        store
          .dispatch("app-support-cases/fetchOrganization", data)
          .then((response) => {
            organization.value = response.data.items;
            if (organization.value.length == 0) {
              noOptionMessage.value = "Sorry, data is not avaialble";
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching organization",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }

      if (userData.role_name == "ACCOUNT_MANAGER") {
        const data = {
          id: userData.id,
          region_id: userData.default_region_id,
        };
        store
          .dispatch("app-support-cases/fetchOrganizationByAccountManager", data)
          .then((response) => {
            organization.value = response.data;
            if (organization.value.length == 0) {
              noOptionMessage.value = "Sorry, data is not avaialble";
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching organizations",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    };

    const deleteItem = (item) => {
      store
        .dispatch("app-support-cases/deleteSupportCases", item.id)
        .then(() => {
          refetchData();
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Support case deleted successfully`,
            },
          });
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Support case could not deleted`,
            },
          });
        });
    };

    const isAddNewSupportCasesSidebarActive = ref(false);

    return {
      // Sidebar
      getOrganizationsForRole,
      userData,
      isAddNewSupportCasesSidebarActive,
      requestTypes,
      status,
      priority,
      organization,
      user,
      fetchSupportCases,
      tableColumns,
      perPage,
      currentPage,
      totalSupportCases,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupportCaseListTable,
      refetchData,
      deleteItem,
      isSearch,
      statusSelect,
      prioritySelect,
      organziationSelect,
      userSelect,
      activeAddSupportCase,
      noOptionMessage,

      // Filter
      avatarText,

      // UI
      resolveSupportCasesRoleVariant,
      resolveSupportCasesRoleIcon,
      resolveSupportCasesStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/variables";
@import "@core/scss/vue/libs/vue-select.scss";
.extra-hours {
  background-color: #fce205;
}

.support-hours-bold {
  padding: 0rem 0.3rem;
}
.support-hours {
  min-width: 10%;
}
.v-select {
  width: 100%;
}
.per-page-selector {
  width: 90px;
}

.custom-email {
  margin: 0.6rem 0rem;
}

.support-filter {
  margin-bottom: 2rem;
}
</style>

<style lang="scss">
.support-cases table tr {
  cursor: pointer;
}
</style>
